<template>
  <b-container ref="samaGora">
    <b-card class="card-page" v-if="!ubezpieczony">
      <b-container>
        <b-row align-h="center" class="text-center" style="margin-top: 20px">
          <b-col>
            <p class="lead">Poczekaj, pobieramy dane z ZUS...</p>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="10">
            <b-progress id="postep" height="2rem" :value="1" :max="1" variant="info" striped animated></b-progress>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-alert class="bledy-zus" variant="danger" dismissible :show="bledyWalidacjiZus.length > 0">
      <h3>Tym razem się nie udało...</h3>
      <p>Wysłaliśmy eZLA do ZUS, jednak jego system informatyczny uznał, że jest ono niepoprawne. Oto co nam odpowiedział:</p>
      <ul v-for="(b, index) in bledyWalidacjiZus" :key="index">
        <li>{{b}}</li>
      </ul>
      <p>Mamy nadzieję, że powyższe komunikaty pomogą Ci zmienić co trzeba i następnym razem się uda!</p>
    </b-alert>
    <b-card class="card-page card-ezla" v-if="ubezpieczony">
      <b-form class="form-insured">
        <fieldset id="ubezpieczony">
          <div class="float-right"
            v-b-tooltip.left="'wróć do wyszukiwania ubezpieczonych'">
            <b-link :to="{name: 'Wyszukiwanie'}"><icon name="times-circle" scale="1.5" class="text-muted-light"></icon></b-link>
          </div>
          <span class="text-muted">Ubezpieczony</span>
          <Ubezpieczony :ubezpieczony="ubezpieczony" class="mb-2"></Ubezpieczony>

          <span class="text-muted">ZLA na opiekę nad</span>
          <RodzinaUbezpieczonego :rodzina="ubezpieczony.rodzina"
            :podopieczny.sync="podopieczny">
          </RodzinaUbezpieczonego>
        </fieldset>

        <hr>

        <fieldset id="kodchoroby" class="form-section">
          <b-row>
            <b-col sm="7">
              <KodChoroby :wymagajKodu="podopieczny === null" :kodChoroby.sync="noweEzla.niezdolnoscDoPracy.numerStatystycznyChoroby"
                @update:kodChoroby="zmienionoKodChoroby">
              </KodChoroby>
            </b-col>
            <b-col sm="5">
              <KodyLiterowe v-model="noweEzla.niezdolnoscDoPracy.kodyLiterowe"></KodyLiterowe>
            </b-col>
          </b-row>

          <b-form-checkbox id="maLezec"
            v-model="noweEzla.niezdolnoscDoPracy.wskazanieLekarskie"
            value="POWINIEN_LEZEC"
            unchecked-value="MOZE_CHODZIC">
            Chory powinien leżeć
          </b-form-checkbox>
        </fieldset>

        <hr>

        <fieldset id="adres" class="form-section">
          <div class="float-right"
            v-b-tooltip.left="'wybierz albo dodaj adres pobytu ubezpieczonego'">
            <icon name="question-circle" scale="1.5" class="text-muted-light"></icon>
          </div>

          <span class="text-muted">Adres pobytu ubezpieczonego</span>
          <AdresUbezpieczonego :adresy="ubezpieczony.adresy"
            :wybrany-adres.sync="noweEzla.adresUbezpieczonego">
          </AdresUbezpieczonego>
        </fieldset>

        <hr>

        <fieldset id="platnik" class="form-section">
          <div class="float-right"
            v-b-tooltip.left="'Wybierz lub dodaj płatników ubezpieczonego. Jeżeli płatnik nie ma PUE musisz przekazać ubezpieczonemu wydruk ZLA. Wydruki dla wszystkich płatników będą dostępne na kolejnym ekranie.'">
            <icon name="question-circle" scale="1.5" class="text-muted-light"></icon>
          </div>

          <span class="text-muted">Płatnicy</span>
          <PlatnicyUbezpieczonego :platnicy="ubezpieczony.platnicy"
            :wybrani-platnicy.sync="noweEzla.platnicy">
          </PlatnicyUbezpieczonego>
          <b-form-checkbox v-if="ubezpieczony.platnicy" v-model="ustaloZatrudnienie">zaświadczenie po ustaniu zatrudnienia</b-form-checkbox>
        </fieldset>

        <hr>

        <fieldset id="niezdolnosc" class="form-section">
          <b-alert v-if="obowiazujaceZwolnienie" show variant="warning">
            Bieżące zwolnienie ubezpieczonego kończy się {{obowiazujaceZwolnienie.niezdolnoscDoPracy.dataDo | formatujDate }}
          </b-alert>
          <NiezdolnoscDoPracy :niezdolnosc.sync="noweEzla.niezdolnoscDoPracy"></NiezdolnoscDoPracy>
        </fieldset>
      </b-form>
      <div class="text-center">
        <WystawPodpisz :przetwarzanie="przetwarzanie"
          @wystaw="zarejestrujZla"
          @pokazBledyWalidacji="pokazBledyWalidacji">
        </WystawPodpisz>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import Ubezpieczeni from '@/services/Ubezpieczeni'
import Podpowiadaczki from '@/services/Podpowiadaczki'
import Ezla from '@/services/Ezla'
import Ubezpieczony from './Ubezpieczony'
import RodzinaUbezpieczonego from './RodzinaUbezpieczonego'
import AdresUbezpieczonego from './AdresUbezpieczonego'
import PlatnicyUbezpieczonego from './PlatnicyUbezpieczonego'
import NiezdolnoscDoPracy from './NiezdolnoscDoPracy'
import KodChoroby from './KodChoroby'
import KodyLiterowe from './KodyLiterowe'
import WystawPodpisz from './WystawPodpisz'
import * as DostawcyPodpisu from '@/constants/DostawcyPodpisu'
import * as KodyBledow from '../../constants/KodyBledow'
import * as Komunikaty from '@/constants/Komunikaty'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'nowe-ezla',
  components: { Ubezpieczony, RodzinaUbezpieczonego, AdresUbezpieczonego, PlatnicyUbezpieczonego, NiezdolnoscDoPracy, KodChoroby, KodyLiterowe, WystawPodpisz },
  data () {
    return {
      czasStart: 0,
      ubezpieczony: null,
      podopieczny: null,
      obowiazujaceZwolnienie: null,
      ustaloZatrudnienie: false,
      noweEzla: {
        idWystawcy: this.$store.state.idWystawcy,
        ubezpieczony: null,
        adresUbezpieczonego: null,
        niezdolnoscDoPracy: {
          podopieczny: null,
          dataOd: null,
          dataDo: null,
          szpitalDataOd: null,
          szpitalDataDo: null,
          kodyLiterowe: [],
          numerStatystycznyChoroby: null,
          wskazanieLekarskie: 'MOZE_CHODZIC'
        },
        platnicy: null,
        autoWydruk: [],
        dostawcaPodpisu: this.$store.state.dostawcaPodpisu,
        hasloDoCertyfikatuZus: null
      },
      przetwarzanie: false,
      bledyWalidacjiZus: []
    }
  },
  computed: {
    ...mapGetters(['maPremium'])
  },
  created () {
    Ubezpieczeni.pobierz(this.$route.params.pesel).then(ubezpieczony => {
      this.ubezpieczony = ubezpieczony
      this.czasStart = performance.now()
    })
    if (this.maPremium) {
      Ezla.wyszukajObowiazujaceUbezpieczonego(this.$route.params.pesel, 'DZIS').then(zwolnienia => {
        if (zwolnienia && zwolnienia.length !== 0) {
          this.obowiazujaceZwolnienie = zwolnienia[0]
          this.noweEzla.niezdolnoscDoPracy.dataOd = this.obowiazujaceZwolnienie.niezdolnoscDoPracy.dataDo
          this.noweEzla.niezdolnoscDoPracy.numerStatystycznyChoroby = this.obowiazujaceZwolnienie.niezdolnoscDoPracy.numerStatystycznyChoroby
        }
      })
    }

    this.przepiszUbezpieczonegoDoEzla = (ubezpieczony) => {
      this.noweEzla.ubezpieczony = {
        instytucjaUbezpieczajaca: this.$store.state.instytucjaUbezpieczajaca,
        imie: ubezpieczony.imie,
        nazwisko: ubezpieczony.nazwisko,
        pesel: ubezpieczony.pesel,
        numerPaszportu: ubezpieczony.numerPaszportu,
        dataUrodzenia: ubezpieczony.dataUrodzenia,
        dataZgonu: ubezpieczony.dataZgonu
      }
    }
    this.ustawPodopiecznego = (podopieczny) => {
      if (podopieczny && podopieczny.kodPokrewienstwa) {
        this.noweEzla.niezdolnoscDoPracy.podopieczny = {
          kodPokrewienstwaOsobyPodOpieka: podopieczny.kodPokrewienstwa,
          dataUrodzeniaOsobyPodOpieka: podopieczny.dataUrodzenia
        }
      }
    }
    this.ustawWydruk = (platnicy) => {
      this.noweEzla.autoWydruk = platnicy
        .filter(p => p.wydruk)
        .map(p => {
          if (p.instytucja) return p.instytucja.nip
          if (p.osoba && p.osoba.pesel) return p.pesel
          return p.osoba.numerPaszportu
        })
    }
    this.ustawPodpis = (dostawca, haslo) => {
      this.noweEzla.dostawcaPodpisu = dostawca
      this.noweEzla.hasloDoCertyfikatuZus = haslo
    }
    this.pokazBledyWalidacji = () => {
      let el = this.$el.querySelector('[data-vv-name="' + this.$validator.errors.items[0].field + '"]')
      if (el) {
        el.scrollIntoView()
      }
    }
  },
  methods: {
    zmienionoKodChoroby (kod) {
      if (kod === null) { return }
      let dataOd = moment(this.noweEzla.niezdolnoscDoPracy.dataOd).format('YYYY-MM-DD')
      Podpowiadaczki.podpowiedzKodyLiterowe(this.ubezpieczony.pesel, dataOd, kod).then(response => {
        if (this.maPremium &&
            this.obowiazujaceZwolnienie &&
            this.obowiazujaceZwolnienie.niezdolnoscDoPracy.numerStatystycznyChoroby === kod &&
            response.indexOf('A') === -1) {
          this.noweEzla.niezdolnoscDoPracy.kodyLiterowe = ['A'].concat(response)
        } else {
          this.noweEzla.niezdolnoscDoPracy.kodyLiterowe = response
        }
      })
    },
    zarejestrujZla (e) {
      this.bledyWalidacjiZus = []
      this.przetwarzanie = true
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.przetwarzanie = false
          this.pokazBledyWalidacji()
          return
        }

        this.przepiszUbezpieczonegoDoEzla(this.ubezpieczony)
        this.ustawPodopiecznego(this.podopieczny)
        this.ustawWydruk(this.noweEzla.platnicy)
        this.ustawPodpis(e.podpis, e.haslo)

        if (this.noweEzla.platnicy.length === 1) {
          this.noweEzla.platnicy[0].ustaloZatrudnienie = this.ustaloZatrudnienie
        }

        Ezla.zarejestruj(this.noweEzla).then(response => {
          if (this.noweEzla.dostawcaPodpisu === DostawcyPodpisu.EPUAP) {
            this.$router.push({ name: 'PrzekierowaniePodpisEpuap', params: { url: response.epuap_url } })
          } else {
            let parametry = {
              co: 'nowe_ezla',
              uuidZwolnien: response.uuid_zwolnien,
              platnicy: this.noweEzla.platnicy
            }
            let czasKoniec = performance.now()
            this.$ga.event('ezla', 'nowe')
            this.$ga.time('ezla', 'wystawienie', czasKoniec - this.czasStart)
            this.$router.push({ name: 'NoweEzlaSukces', params: parametry })
          }
        }, blad => {
          this.przetwarzanie = false
          if (blad.body.kod === KodyBledow.ZLE_HASLO_DO_CERTYFIKATU) {
            this.niepoprawneHaslo()
          } else if (blad.body.kod === KodyBledow.BLAD_WALIDACJI_USLUGI_ZUS) {
            this.bledyWalidacjiZus = blad.body.opis
            this.$refs.samaGora.scrollIntoView({ behavior: 'smooth' })
          } else if (blad.body.kod === KodyBledow.BRAK_PLATNIKA) {
            this.brakPlatnika()
          } else {
            this.bladWystawieniaEzla()
          }
        })
      })
    }
  },
  notifications: {
    niepoprawneHaslo: {
      title: Komunikaty.ZLE_HASLO_NOWE_EZLA.TYTUL,
      message: Komunikaty.ZLE_HASLO_NOWE_EZLA.TRESC,
      type: 'error',
      timeout: 7000
    },
    brakPlatnika: {
      title: 'Błąd!',
      message: 'Dla ubezpieczonych w ZUS musisz wprowadzić dane płatnika.',
      type: 'error',
      timeout: 7000
    },
    bladWystawieniaEzla: {
      title: 'Błąd!',
      message: 'Błąd podczas wystawiania nowego eZLA.',
      type: 'error',
      timeout: 7000
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

@media (max-width: $md) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.card-ezla {
  max-width: $md;
}

.bledy-zus {
  max-width: $md;
  margin: 20px auto 20px auto;
}

.form-ubezpieczony {
  padding: 1rem 0;
}

.form-section {
  margin: 1rem 0;
}

.btn-group {
  margin-bottom: 1rem;
}

.btn-submit {
  margin-top: 1rem;
}
</style>
