<template>
  <div>
    <transition name="slide-fade" mode="out-in">
      <span v-if="!trybZmianaDanych">
        <span class="text-heading">{{ubezpieczony.pesel}} - {{ubezpieczony.imie}} {{ubezpieczony.nazwisko}}</span>

        <b-badge href="#"
          class="ml-2"
          variant="light"
          @click="kopiujDane()"
          v-b-tooltip="'edycja danych'">
          <icon name="pencil" scale="1.5" class="text-muted"></icon>
        </b-badge>

        <poprzednie-zwolnienia :pesel="ubezpieczony.pesel"></poprzednie-zwolnienia>

        <br>
      </span>

      <div v-if="trybZmianaDanych">
        <b-form-row>
          <b-col sm="6">
            <b-form-group label="Imię:"
                          :invalid-feedback="errors.first('imie')">
              <b-form-input name="imie"
                            v-model="ubezpieczony.imie"
                            v-validate="'required'"
                            data-vv-as="imię"
                            :state="!this.errors.has('imie')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Nazwisko:"
                          :invalid-feedback="errors.first('nazwisko')">
              <b-form-input name="nazwisko"
                            v-model="ubezpieczony.nazwisko"
                            v-validate="'required'"
                            :state="!this.errors.has('nazwisko')">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>
    </transition>

    <div v-if="trybZmianaDanych" class="text-right">
      <b-button class="mr-2"
        variant="primary"
        @click="zapisz()">
        zapisz
      </b-button>
      <b-link class="text-muted-light"
        @click="cofnij()">
        <icon name="undo" scale="0.7"></icon> cofnij
      </b-link>
    </div>
  </div>
</template>

<script>
import PoprzednieZwolnienia from './PoprzednieZwolnienia'

export default {
  name: 'ubezpieczony',
  props: ['ubezpieczony'],
  components: { PoprzednieZwolnienia },
  inject: ['$validator'],
  data () {
    return {
      trybZmianaDanych: false,
      stareDane: {
        imie: null,
        nazwisko: null
      }
    }
  },
  methods: {
    kopiujDane () {
      this.stareDane.imie = this.ubezpieczony.imie
      this.stareDane.nazwisko = this.ubezpieczony.nazwisko
      this.trybZmianaDanych = true
    },
    cofnij () {
      this.ubezpieczony.imie = this.stareDane.imie
      this.ubezpieczony.nazwisko = this.stareDane.nazwisko
      this.trybZmianaDanych = false
    },
    zapisz () {
      this.trybZmianaDanych = false
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-edit {
  color: inherit;
}

.text-heading {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
