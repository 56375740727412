<template>
  <b-container class="container-search">

    <b-card no-body class="card-page">

      <b-card-header>
        <b-dropdown right variant="light" size="lg"
          :text="'Wystaw zwolnienie dla ubezpieczonego w ' + instytucjaUbezpieczajacaOpis">
          <b-dropdown-item href="#" :active="wybranyZus" @click="wybierz('ZUS')">ZUS</b-dropdown-item>
          <b-dropdown-item href="#" :active="wybranyKrus" @click="wybierz('KRUS')">KRUS</b-dropdown-item>
          <b-dropdown-item href="#" :active="wybranyInnyPl" @click="wybierz('INNY_PL')">inny w Polsce</b-dropdown-item>
          <b-dropdown-item href="#" :active="wybranyInnyPozaPl" @click="wybierz('INNY_POZA_PL')">innym państwie</b-dropdown-item>
        </b-dropdown>

        <div class="container-select my-1">

          <v-select id="kryteriaWyszukiwania"
            inputId="wyszukiwanieInput"
            :on-search="wyszukaj"
            :options="ubezpieczeni"
            :clear-search-on-select="false"
            :on-change="wybrano"
            placeholder="Wprowadź PESEL lub nazwisko"
            class="select-search">
            <template slot="option" slot-scope="option">
              <icon name="user-circle-o"></icon>
              {{ option.label }}
            </template>
          </v-select>
        </div>

        <b-popover :show.sync="pokazWskazowke"
          triggers=""
          target="kryteriaWyszukiwania"
          title="Uwaga!"
          placement="bottom">
          Nie znaleźliśmy żadnego pacjenta o nazwisku zaczynającym się od <b-badge>{{ fraza }}</b-badge>.
          Wpisz PESEL lub numer paszportu, abyśmy mogli zapytać ZUS o jego dane.
        </b-popover>

        <b-popover :show.sync="blednyPesel"
          triggers=""
          target="kryteriaWyszukiwania"
          title="Uwaga!"
          placement="bottom">
          Niepoprawny numer PESEL, sprawdź czy nie zrobiłeś literówki.
        </b-popover>

      </b-card-header>
    </b-card>
  </b-container>
</template>

<script>
import Ubezpieczeni from '../services/Ubezpieczeni'
import * as ZmianyStanu from '../store/ZmianyStanu'
import { mapState } from 'vuex'
import debounce from 'debounce'
import * as Walidator from 'validate-polish'

export default {
  name: 'wyszukiwanie',
  data () {
    return {
      fraza: '',
      ubezpieczeni: [],
      pokazWskazowke: false,
      blednyPesel: false,
      czasNacisnieciaPrzycisku: null
    }
  },
  created () {
    this.wyszukaj = debounce(this.wyszukaj, 300)

    this.czyPesel = (fraza) => {
      return Walidator.pesel(fraza)
    }
    this.czyNazwisko = (fraza) => {
      return /^[a-zA-Z- ]+$/.test(fraza)
    }
    this.budujOpisUbezpieczonego = (u) => {
      u.label = `${u.imie} ${u.nazwisko} `
      u.label += u.pesel ? u.pesel : u.numerPaszportu
      return u
    }
  },
  mounted () {
    document.getElementById('wyszukiwanieInput').focus()
  },
  computed: {
    ...mapState({
      instytucjaUbezpieczajacaOpis (state) {
        switch (state.instytucjaUbezpieczajaca) {
          case 'ZUS':
            return 'ZUS'
          case 'KRUS':
            return 'KRUS'
          case 'INNY_PL':
            return 'innej instytucji w Polsce'
          case 'INNY_POZA_PL':
            return 'innym państwie'
        }
      },
      wybranyZus (state) {
        return state.instytucjaUbezpieczajaca === 'ZUS'
      },
      wybranyKrus (state) {
        return state.instytucjaUbezpieczajaca === 'KRUS'
      },
      wybranyInnyPl (state) {
        return state.instytucjaUbezpieczajaca === 'INNY_PL'
      },
      wybranyInnyPozaPl (state) {
        return state.instytucjaUbezpieczajaca === 'INNY_POZA_PL'
      }
    })
  },
  methods: {
    potencjalnyPesel (p) {
      return /^\d{11}$/.test(p)
    },
    wybierz (ubezpieczyciel) {
      this.$store.commit({
        type: ZmianyStanu.USTAW_INSTYTUCJE_UBEZPIECZAJACA,
        instytucjaUbezpieczajaca: ubezpieczyciel
      })
    },
    wyszukaj (search, loading) {
      this.blednyPesel = false
      this.fraza = search
      if (this.czyPesel(this.fraza)) {
        this.$router.push({ name: 'NoweEzla', params: { pesel: this.fraza } })
      } else if (this.potencjalnyPesel(this.fraza)) {
        this.blednyPesel = true
      } else {
        loading(true)
        Ubezpieczeni.wyszukaj(this.fraza).then(ubezpieczeni => {
          this.pokazWskazowke = this.fraza.length > 1 && this.czyNazwisko(this.fraza) && ubezpieczeni.length === 0
          this.ubezpieczeni = ubezpieczeni.map(this.budujOpisUbezpieczonego)
          loading(false)
        })
      }
    },
    wybrano (u) {
      if (this.czyPesel(u)) {
        this.$router.push({ name: 'NoweEzla', params: { pesel: u } })
        return
      }
      if (u.pesel === undefined) {
        return
      }
      this.$router.push({
        name: 'NoweEzla',
        params: {
          pesel: u.pesel ? u.pesel : u.numerPaszportu
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .container-search {
    margin-top: 80px
  }

  .container-select {
    position: relative;
  }

  .b-dropdown /deep/ .dropdown-toggle {
    padding: 0 0 8px 0;
    background: none;
    border: none;
    text-align: left;
    white-space: normal;
  }

  .v-select /deep/ .dropdown-toggle > .form-control {
    padding: 1.5rem 0.75rem;
    font-size: 1.5rem;
    line-height: 2;
    letter-spacing: 0.1em;

    &:focus {
      padding: 1.5rem 0.75rem;
      font-size: 1.5rem;
      line-height: 2;
      letter-spacing: 0.1em;
    }

    &::placeholder {
      padding: 0.3rem 0;
      font-size: 1.2rem;
      line-height: 2;
    }

    &::-moz-placeholder  {
      line-height: 0;
    }
  }
</style>
